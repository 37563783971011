function ReactTooltip({ text, children, width, tooltipWidth, position }) {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <div className="tooltip-container" style={{ width: `${width}%`}}>
      {React.cloneElement(children, {
        onMouseEnter: () => {
          setShowTooltip(true);
        },
        onMouseLeave: () => {
          setShowTooltip(false);
        },
      })}
      <ReactTransitionGroup.CSSTransition
        in={showTooltip}
        timeout={5000000}
        classNames="tooltip"
        unmountOnExit
      >
        <div className={position === 'bottom' ? 'tooltip-bottom' : "tooltip" }
          style={{
            width: tooltipWidth ? tooltipWidth : 106,
            textAlign: "center",
          }}
        >
          {text}
        </div>
      </ReactTransitionGroup.CSSTransition>
    </div>
  );
}
